@tailwind base;
@tailwind components;
@tailwind utilities;

/* width */
::-webkit-scrollbar {
    width: .3em;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #67C748;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #67C748;
  }