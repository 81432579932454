#components-layout-demo-fixed-sider .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
}
.site-layout .site-layout-background {
  background: #fff;
}

.logo__container {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 10px;
  flex: 1;
  width: 100%;
  padding: 30px 0;
  
  .logo-image {
    width: 80px;
    height: 80px;
  }
}