.auth_page {
  display: flex;
  flex: 1;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.margin_vertical {
  margin: 10px 0;
}

.form__container {
  width: 40%,
}